<template>
	<!--企业入驻-->
	<mainBox >
		<div class="wapBox">
			<div >
				<div class="eBtns">
					<el-button class='b_it' @click='toAddRetreat()' type="primary" >发起退租</el-button>
					<el-button class='b_it' @click='refreshTable' icon="el-icon-refresh-right">刷新</el-button>
					<el-button class='b_it sxBtn' @click.stop='showRowQuery'>筛选
					 	<i class="el-icon-arrow-down" v-if="rowQuery"></i>
        		<i class="el-icon-arrow-right" v-else></i>
					</el-button>
				</div>
				<div class="tbBox ">
					<TTable2 class='' ref="myTable" :tbloading='tbloading' :border='false' :showQuery='rowQuery' @rowSeach='rowSeach' :tableData="tableData" :tableTitle="tableTitle">
						<template slot='endingStatusStr' slot-scope="scope">
							<span :class="'txColor'+scope.row.endingStatus"> {{scope.row.endingStatusStr}}</span>
						</template>
						<template slot='operating' slot-scope="scope">
							<el-button type="text" @click='tbRowDetail(scope.row)'>查看</el-button>
							<el-button type="text" v-if='scope.row.endingStatus==1' @click='tbRowRetion(scope.row)'>预约</el-button>
							<el-button type="text" v-if='scope.row.endingStatus==2' @click.stop="uploadByType(scope.row,'up1')" >上传</el-button>
							<el-button type="text" v-if='scope.row.endingStatus==3' @click.stop="uploadByType(scope.row,'up2')" >上传结算表</el-button>
							<el-button type="text" v-if='scope.row.endingStatus==5' @click.stop="toAddRetreat(scope.row)" >重新提交</el-button>
						</template>
					</TTable2>
					<TPagination v-model="tableParams" @initTable="initTable"></TPagination><!-- 分页 -->
				</div>
			</div>
			<Reservation v-if='yyjfInfo.open' :isOpen='yyjfInfo.open' :datas='yyjfInfo.datas' @close='closeDeliver'  />
			<!--导入文件-->
	    <el-dialog :visible.sync="upFiles.isOpen" width="40%">
	      <template slot="title">
	        <title-icon />{{ $t("commons.uploadFile") }}
	      </template>
	      <div slot="footer">
	        <el-upload ref="Yupload"
			      :action="upFiles.rootPath"
			      :file-list="upFiles.fileList"
			      :on-success="uploadSuccess"
			      :on-change="fileChange"
			      :on-error="uploadFail"
			      :with-credentials="true"
			      :auto-upload="false"
			    >
			      <el-button slot="trigger" size="small" type="primary">{{$t("commons.chooseFile")}}</el-button>
			      <el-button size="small" type="success" @click="submitFiles" style="margin-left: 10px">{{ $t("commons.uploadToBg") }}</el-button
			      >
			    </el-upload>
	      </div>
	    </el-dialog>
		</div>
	</mainBox>
</template>
<script>
	import pagination from "@/mixins/TTable/pagination";
	import seachAndTable from "@/mixins/TTable/seachAndTable";
	import TTable2 from '@/components/YTable/TTable2.vue';
	import TPagination from '@/components/YTable/TPagination.vue';
	import { envInfo } from "@/constants/envInfo";
	import Upload from "@/components/Upload";
	import Reservation from './model/Reservation.vue'
	const statusArrs=[
		//入驻状态 0待审核 1待退租 2待回执 3待结算 4已退租 5已驳回
		{label:'待审核',value:0},
		{label:'待退租',value:1},
		{label:'待回执 ',value:2},
		{label:'待结算 ',value:3},
		{label:'已退租',value:4},
		{label:'已驳回',value:5},
	]
	export default {
		mixins: [pagination, seachAndTable],
		components: {TTable2,TPagination,Upload,Reservation},
		data() {
			return {
				tableTitle: [
					{
						name: '创建时间',
						prop: "creationDateStr",
					},
					{
						name: '企业名称',
						prop: "enterpriseName",
						queryInfo: {
							type: 'input',
							value: "",
						}
					},
					{
						name: '所属项目',
						prop: "projectName",
						queryInfo: {
							type:'select',
							value: "",
							options:[],
						}
					},
					{
						name: '退租日期',
						prop: "rentEndingDateStr",
						queryInfo: {
							type: 'date',
							valueFormat:'timestamp',
							value: "",
						}
					},
					{
						name: '退租联系人',
						prop: "endingLiaison",
						queryInfo: {
							type: 'input',
							value: "",
						}
					},
					{
						name: '退租状态',
						prop: "endingStatus",
						slotName:'endingStatusStr',
						queryInfo: {
							type:'select',
							value:'',
							options:statusArrs,
						}
					},
					{name: '责任部门',prop: "deptName",},
					{
						name: '操作',
						width: "180",
						fixed: 'right',
						slotName: "operating",
					},
				],
				tableData: [],
				yyjfInfo: { //预约场地交付
					open: false,
					datas:{},
				},
				//上传相关 -复制之前
				upFiles:{
					up1:'uploadReceipt',//上传回执
					up2:'uploadSettlement',//上传结算
					nowUp:'up1',//上传方式
					isOpen:false,
					fileList:[],
					documentType: "contract_type",
  				rootPath: envInfo.bgApp.basePath,
				},
				//end
			};
		},
		watch: {},
		created() {
			this.initProjectArrs();
		},
		mounted() {
		},
		computed: {},
		methods: {
			async initProjectArrs(){
				let res =await this.$store.dispatch('getAllProjects');
				if(res){
  				res.map(it=>{
						it.label = it.projectName;
						it.value = it.projectId;
					})
					this.common.injectionOption2('projectName',res,this.tableTitle)
				}
			},
			tbRowRetion(row){//预约
				this.yyjfInfo={
					open: true,
					datas:row,
				}
			},
			tbRowDetail(row){
				let params = {
	        path: "/rwork/enterprise/retreatDetail",
	        query: {
	          orderId: row?row.rentEndingId:'',
	        },
	      };
	      this.$router.push(params);
			},
			closeDeliver(isInit) {//关闭场地交付弹窗时
				this.yyjfInfo.open=false;
				if(isInit){
					this.initTable();
				}
			},
			toAddRetreat(row){
				let params = {
	        path: "/rwork/enterprise/toRetreat",
	        query: {
	          rentEndingId: row?row.rentEndingId:'',
	        },
	      };
	      this.$router.push(params);
			},
			async initTable() {
				this.tbloading = true;
				let params = {
					current: this.tableParams.current,
					rowCount: this.tableParams.rowCount,
					...this.rowFilterData,
				}
				if(params.projectName) params.projectId=params.projectName;
				delete params.projectName;
				let res = await this.ApiHttp('/organization/enterprise/rent/endingOrderPage', params);
				let Arrs=res.rows||[];
				Arrs.map(it=>{
					it.endingStatusStr=statusArrs[+it.endingStatus].label;
					it.rentEndingDateStr=this.common.getTimeMmss(it.rentEndingDate,'day');
					it.creationDateStr=this.common.getTimeMmss(it.creationDate);
					it.deptName='招商部'
				})
				this.tableData = Arrs
				this.tableParams.total = res.total;
				this.$nextTick(() => {
					this.tbloading = false;
				})
			},

			derMateriel(row) {
				this.$confirm('是否确认删除', '提示').then(() => {
					this.ApiHttp('/base/tenant/materiel/delete', row, 'delete').then(res => {
						if(res) {
							this.initTable();
						}
					});

				}).catch(() => {});
			},

		
			
		
			uploadByType(row,val){
				//val  1=上传场地验收回执表    2=上传结算表
				this.upFiles.isOpen=true;
				this.upFiles.nowUp=val;
				this.upFiles.rentEndingId=row.rentEndingId;
				this.upFiles.enterpriseId=row.enterpriseId;
			},
			async submitFiles() {
				let Arrs=[];
				for(let item of this.upFiles.fileList){
					let res=await this.httpFiles(item.raw);
					console.log('res',params)
					if(res){
						Arrs.push(res);
					}
				}
				if(Arrs.length<1){
					this.$message('请选择上传文件');
					return;
				}
				let params={
					rentEndingId:this.upFiles.rentEndingId,
					enterpriseDocumentUploadVO:{
						documentType:this.upFiles.documentType,
						enterpriseId:this.upFiles.enterpriseId,
						files:Arrs,
					},
				}
				let upUrl=this.upFiles[this.upFiles.nowUp];
				let bkinfo=await this.ApiHttp('/organization/enterprise/rent/'+upUrl,params,'post');
				if(bkinfo){
					console.log('bkinfo',bkinfo)
					this.initTable();
					this.uploadSuccess();
					this.$message({type: 'success',message: '操作成功'});
					
				}
//	      this.$refs.Yupload.submit();
	    },
	    async httpFiles(myFile){
	    	let _result;
	    	if(myFile) {
					if(myFile.size>20*1024*1024){
						this.$message('文件大小不能超过20M');
						return;
					}
					let formData=new FormData();
					formData.append('file',myFile);
					let backRes = await this.ApiHttp('/archive/param/archive/upload?ulType=DefaultUpload',formData,'post',true);
					setTimeout(()=>{
						this.uploading=false;
					},500)
					if(backRes&&backRes.length>0){
						_result={
							documentName:myFile.name,//名称
							fileId: backRes[0].fileId,//id
							documentFormat: backRes[0].contentType,//格式
						}
					}
				}
	    	return _result;
	    },
     	async fileChange(myFile, fileArrs){
	    	this.upFiles.fileList = fileArrs;
	    	console.log('fileChange',fileArrs)
	    },
	    uploadSuccess() {
      	this.upFiles.isOpen = false;
	      this.upFiles.fileList = [];
	    },
	    uploadFail() {
	      this.$message({ type: "error", message: this.$t("message.uploadFial") });
	    },
		},
	};
</script>

<style scoped lang="scss">
	.wapBox {
		height: 100%;
		.flexs {
			height: 100%;
		}

		.right {
			padding: 16px 24px;
			.eBtns {
				position: relative;
				.el-button {
					padding: 10px 18px;
				}
				.sxBtn{
					position: absolute;
					right: 0;
				}
			}
		}
		.txColor0{
	    padding: 0 6px;
	   	color: #4e78ff;
    	background: #f4f6fe;
		}
		.txColor4{
	    padding: 0 6px;
	   	color: #78d549;
    	background: #f2faee;
		}
		.txColor5{
	    padding: 0 6px;
	   	color: #e8331c;
  		background: #fef5f4;
		}
	}
</style>
<style type="text/css">
	.tbTreeCheck thead .el-checkbox {
		display: none;
	}
	
	.materielBox .m-body {
		padding: 0 !important;
	}
	.dfNode1 {
		.el-tree-node__label {
			color: #1A4CEC;
		}
	}
	
	.drBtn.el-button--text {
		color: #2F3941 !important;
	}
</style>